<div class="w-full">
  <form class="flex items-center" (submit)="whenSubmit.emit($event)">
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <fa-icon [icon]="faSearch"></fa-icon>
      </div>
      <input
        id="simple-search"
        class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg"
        placeholder="Search"
        type="text"/>
    </div>
  </form>
</div>
