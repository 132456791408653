import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'eb-ui-logo',
  standalone: true,
  imports: [CommonModule],
  template: `<img src="/assets/favicon.png" alt="" class="w-10 max-w-full object-cover" /> `,
  styles: ``,
})
export class LogoComponent {}
