import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[ebUiButton]',
  standalone: true,
})
export class ButtonDirective {
  @Input() variant: 'primary' | 'outline' | 'link' | 'dark' = 'primary';

  @HostBinding('class')
  get classes(): string {
    // const defaultTemplate: string =
    //   'inline-block w-full px-12 py-3 text-sm font-medium transition border rounded-md shrink-0 outline-0 focus:outline-0 focus:ring';
    const defaultTemplate: string =
      'inline-block w-full px-4 py-2 text-sm font-medium transition border rounded-md shrink-0 outline-0 focus:outline-0 focus:ring';

    const primaryTemplate: string =
      'text-white border-giants-orange-600 bg-giants-orange-600 hover:bg-white hover:text-giants-orange-600 disabled:bg-giants-orange-300 disabled:text-white active:text-giants-orange-500  focus:ring-giants-orange-100';

    const outlineTemplate: string =
      'text-giants-orange-600 border-giants-orange-600 bg-white hover:bg-giants-orange-600 hover:text-white disabled:bg-giants-orange-300 disabled:text-white active:text-giants-orange-500 focus:ring-giants-orange-100';

    switch (this.variant) {
      case 'primary':
        return `${defaultTemplate} ${primaryTemplate}`;

      case 'outline':
        return `${defaultTemplate} ${outlineTemplate}`;

      case 'dark':
        // return `${defaultTemplate} flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-nora hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:outline-none`;
        return `${defaultTemplate} text-white border-nora-500 bg-nora-500 hover:bg-gray-600 hover:text-white disabled:bg-nora-300 disabled:text-white active:text-white focus:ring-nora-800`;

      default:
        return defaultTemplate;
    }
  }
}
